<template>
    <div class="botmain">
        <div class="usermbox" v-if="!dialogVisible">
            <div class="topbox">
                <div class="tleft">
                    <div class="logo">
                        <img src="@/assets/images/zqzs/xzy.png" />
                    </div>
                    晓知因AI智控机器人后台管理
                </div>
            </div>
            <div class="mainbox">
                <div class="selectbox">
                    <div class="schitem"><el-input v-model="sdata.username" placeholder="输入账号名称"></el-input></div>
                    <div class="schitem"><el-input type="number" v-model="sdata.phone" placeholder="输入联系电话"></el-input>
                    </div>
                    <div class="schitem">
                        到期时间：<el-date-picker v-model="sdata.times" style="width: 350px;" type="daterange"
                            range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="schitem">
                        <el-button type="primary" @click="getlist">搜索</el-button>
                        <el-button type="default" @click="clearsch">重置</el-button>
                    </div>
                </div>
                <div class="adduserbox">
                    <el-button type="primary" @click="goadd">新增用户</el-button>
                </div>

            </div>
            <div class="tablemain">
                <el-table :data="userlist" border stripe>
                    <!-- <el-table-column prop="id" label="编号" width="80">
                    </el-table-column> -->
                    <el-table-column prop="nickname" label="姓名">
                    </el-table-column>
                    <!-- <el-table-column prop="" label="头像">
                        <template slot-scope="scope">
                            <img :src="scope.row.head" style="width: 50px;height: 50px;" />
                        </template>
</el-table-column> -->
                    <el-table-column prop="phone" label="联系电话">
                    </el-table-column>
                    <el-table-column prop="expireTime" label="VIP到期时间">
                    </el-table-column>

                    <el-table-column prop="remark" label="备注">
                    </el-table-column>
                    <el-table-column prop="subscribeRobotNames" label="已订阅">
                        <template slot-scope="scope">
                            {{ scope.row.subscribeRobotNames.join('、') }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="createTime" label="创建时间">
                        <template slot-scope="scope">
                            {{ getformattime(scope.row.createTime * 1000) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="goup(scope.row)">修改</el-button>
                            <el-button :type="scope.row.interdict ? 'success' : 'warning'" size="mini"
                                @click="stop(scope.row.id, scope.row.interdict)">{{ scope.row.interdict ? '启用' : '禁用'
                                }}</el-button>
                            <!-- <el-button type="danger" size="mini" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background page-size="10" :total="wdcount" @current-change="pagechange">
                </el-pagination>
            </div>
        </div>


        <el-dialog :title="editinfo.id ? '修改账号' : '创建账号'" :visible.sync="addboxflag" width="700px" height="800px"
            center>
            <div class="dialogitem">
                <p>账号姓名：</p>
                <el-input placeholder="输入账号姓名" v-model="cdata.name">
                </el-input>
            </div>
            <div class="dialogitem">
                <p>联系电话：</p>
                <el-input placeholder="输入联系电话" v-model="cdata.phone">
                </el-input>
            </div>

            <div class="dialogitem">
                <p>到期时间：</p>
                <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="cdata.viptime" type="date"
                    placeholder="选择日期">
                </el-date-picker>
            </div>

            <div class="dialogitem">
                <p>可创建机器人数量：</p>
                <el-input placeholder="输入机器人数量" type="number" v-model="cdata.botnum">
                </el-input>
            </div>
            <div class="dialogitem">
                <p>备注：</p>
                <el-input placeholder="输入备注" type="textarea" v-model="cdata.remark">
                </el-input>
            </div>
            <div class="dialogitem">
                <p>订阅机器人：</p>
                <el-select v-model="cdata.glbot" multiple placeholder="请选择订阅机器人">
                    <el-option v-for="item in aibotlist" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>



            <span slot="footer" class="dialog-footer">
                <el-button type="default" @click="addboxflag = false">取消</el-button>
                <el-button type="primary" @click="gochangeuser">{{ editinfo.id ? '修改' : '创建' }}</el-button>
            </span>
        </el-dialog>

        <el-dialog title="验证" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
            :close-on-press-escape="false" :show-close="false">
            <div>
                <el-input placeholder="请输入密钥进入" v-model="pwd" show-password></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitlogin">验证</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { getmaguserlist, addmaguser, editmaguser, getuserbyid } from '@/api/user'
import { formatTime } from '@/utils/format'
import VueMarkdown from 'vue-markdown'
import { getaibotlists } from '@/api/bot'
export default {
    name: '',
    components: { VueMarkdown },
    data(props) {
        return {
            dialogVisible: true,
            pwd: '',
            wdcount: 0,
            sdata: {
                username: '',
                phone: '',
                times: [],
                glbot: []
            },
            aibotlist: [],
            userlist: [],
            cdata: {
                viptime: '',
                name: '',
                phone: '',
                botnum: 0,
                remark: ''

            },
            page: 1,
            addboxflag: false,
            editinfo: []

        }
    },
    props: {
    },
    mounted() {

        this.getlist()
        this.getaibotlist()
    },
    watch: {

    },
    methods: {
        submitlogin() {
            if (this.pwd == '4HF3jfg!') {
                this.$message.success('验证通过')
                this.dialogVisible = false
            } else {
                this.$message.error('验证失败')
            }
        },
        clearsch() {
            this.sdata = {
                username: '',
                phone: '',
                times: [],
            }
            setTimeout(() => {
                this.getlist()
            }, 200);
        },
        pagechange(e) {
            console.log(e)
            this.page = e;
            this.getlist()
        },
        goadd() {
            this.editinfo = []
            this.cdata = {
                viptime: '',
                name: '',
                phone: '',
                botnum: 0,
                remark: ''
            }
            this.addboxflag = true;
        },
        goup(item) {
            getuserbyid({ id: item.id }).then(res => {
                console.log(res)
                this.editinfo = res.data
                this.cdata = {
                    viptime: res.data.expireTime,
                    name: res.data.nickname,
                    phone: res.data.phone,
                    botnum: res.data.robotNumber || 0,
                    remark: res.data.remark,
                    glbot: res.data.subscribeRobotIds
                }
                this.addboxflag = true;
            })

        },
        stop(id, status) {
            editmaguser({ id: id, interdict: !status }).then(res => {
                console.log('edituser', res);
                if (res.code == 200) {
                    this.$message.success('修改成功')
                    this.addboxflag = false;
                    setTimeout(() => {
                        this.getlist()
                    }, 200);
                } else {
                    this.$message.error(res.msg)
                }

            })
        },
        del(id) {

        },
        getaibotlist() {
            getaibotlists().then(res => {
                console.log('aibotlist', res)
                this.aibotlist = res.data
            })
        },
        gochangeuser() {
            console.log('first')
            const params = this.editinfo.id ? {
                id: this.editinfo.id,
                expireTime: this.cdata.viptime,
                robotNumber: this.cdata.botnum,
                phone: this.cdata.phone,
                nickname: this.cdata.name,
                remark: this.cdata.remark,
                aiRobotIds: this.cdata.glbot
            } : {
                expireTime: this.cdata.viptime,
                robotNumber: this.cdata.botnum,
                phone: this.cdata.phone,
                nickname: this.cdata.name,
                remark: this.cdata.remark,
                aiRobotIds: this.cdata.glbot

            }
            if (this.editinfo.id) {
                editmaguser(params).then(res => {
                    console.log('edituser', res);
                    if (res.code == 200) {
                        this.$message.success('修改成功')
                        this.addboxflag = false;
                        setTimeout(() => {
                            this.getlist()
                        }, 200);
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            } else {
                addmaguser(params).then(res => {
                    console.log('adduser', res);
                    if (res.code == 200) {
                        this.$message.success('添加成功')
                        this.addboxflag = false;
                        setTimeout(() => {
                            this.getlist()
                        }, 200);
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            }

        },
        getformattime(time) {
            return formatTime(time)
        },
        getlist() {
            console.log(this.sdata.times)
            const params = {
                page: this.page,
                pageSize: 10,
                phone: this.sdata.phone,
                nickname: this.sdata.username,
                expireStartTime: this.sdata.times ? this.sdata.times[0] : '',
                expireEndTime: this.sdata.times ? this.sdata.times[1] : '',

            }
            getmaguserlist(params).then(res => {
                console.log('userlist', res);
                if (res.code == 200) {
                    this.wdcount = res.data.totalCount
                    this.userlist = res.data.list
                } else {
                    this.$message.error(res.msg)
                }

            })
        }
    }
}
</script>
<style lang='less' scoped>
.botmain {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #f6f6f6;

    .usermbox {
        .topbox {
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 20px;
            background-color: white;

            .tleft {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;

                .logo {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #7b75ff;
                    overflow: hidden;
                    margin-right: 20px;

                    img {
                        width: 40px;
                    }

                }
            }
        }

        .mainbox {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            padding: 0 20px;

            .selectbox {
                display: flex;
                justify-content: flex-start;
                background-color: #fff;
                padding: 20px;
                width: calc(100% - 200px);

                .schitem {
                    margin: 0 10px;
                    font-size: 16px
                }
            }

            .adduserbox {
                background-color: #fff;
                padding: 20px;
                width: 200px;
                text-align: right;
            }
        }

        .tablemain {
            margin: 0 20px;
        }
    }
}

.dialogitem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 10px;
    width: calc(50% - 20px);
    font-size: 16px;
    position: relative;
    flex-wrap: wrap;

    p {
        width: 100%;
        margin-bottom: 5px;
    }


}

.dialogitem /deep/ .el-input {
    width: 250px;
}

/deep/ .el-date-editor .el-range-separator {
    width: 9%;
}

/deep/ .el-dialog__body {
    display: flex;
    flex-wrap: wrap;
}

/deep/ .el-pagination {
    background-color: #fff;
    padding: 20px 10px;
}

/deep/ .el-popper .popper__arrow::after {
    border-bottom-color: #fff !important;
}

/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
    background-color: #409eff !important
}
</style>