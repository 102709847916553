// 接口
import axios from '@/axios'
import { baseUrl } from '@/axios/baseUrl'
// 机器人列表
export const getBotList = () => axios.post(baseUrl + '/api/robot/list')
// 机器人列表
export const getglBotList = () => axios.post(baseUrl + '/api/robot/relevanceRobotList')
// 创建机器人
export const robootCreate = (params) => axios.post(baseUrl + '/api/robot/create', params)
// 创建机器人
export const robootEdit = (params) => axios.post(baseUrl + '/api/robot/edit', params)
// 删除机器人
export const robootDel = (params) => axios.post(baseUrl + '/api/robot/delete', params)
// 机器人统计
export const countmybot = (params) => axios.post(baseUrl + '/api/robot/addRobotUsedCount', params)
// 获取机器人二维码
export const getbotqrcode = (params) => axios.post(baseUrl + '/api/poster/generateQRCode', params)
    // 获取机器人信息
export const getbotinfo = (params) => axios.post(baseUrl + '/api/robot/getRobotByIdVisitor', params)
//获取产业机器人所有信息
export const getaibotlists = () => axios.post(baseUrl + '/api/robot/aiList')




